<template>
  <div class="user-manager-container">
    <SectionHeader title="User Management">
      <template v-slot:searchbar>
        <div class="search-bar field no-margin">
          <span class="icon input-icon">
            <i class="fas fa-search"></i>
          </span>
          <input type="text" class="input has-icon" placeholder="Find user" v-model="searchTerm" @input="search" />
        </div>
      </template>
    </SectionHeader>
    <!-- List of Users -->
    <div class="user-list">
      <div class="info-row info-row--flush">
        <button class="button" @click="showAddUserModal = true">
          Create new user
        </button>
      </div>
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Organisation</th>
              <th>Email</th>
              <th>Access Level</th>
              <th>Active</th>
              <th>Two Factor Authentication</th>
              <th>Reset Password</th>

            </tr>
          </thead>
          <tbody name="table-row" is="transition-group">
            <tr v-for="user in filteredUsers" :key="user.id">
              <td>{{ user.name ? user.name : "" }}</td>
              <td>{{ user.organisationName ? user.organisationName : "" }}</td>
              <td>{{ user.email ? user.email : '' }}</td>
              <td>{{ user.role ?  user.role : '' }}</td>
              <td>{{ user.active ? user.active : '' }}</td>
              <td><button :disabled="!user?.organisation?.twoFactorAuth" @click="toggleTwoFactorAuth(user)"
                  :class="user.twoFactorAuth ? 'button__list-item__true' : 'button__list-item__false'">{{
                    user.twoFactorAuth ? "Deactivate" : "Activate" }}</button></td>

              <td>
                <button @click="sendPasswordReset(user)" class="button__list-item">
                  Reset Password
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Create User Modal -->
    <div class="modal" :class="{ 'is-active': showAddUserModal }">
      <div class="modal-background" @click.stop="showAddUserModal = false" />
      <div class="modal-content">
        <div class="modal-title">Create new user</div>
        <div class="modal-body">
          <div class="field">
            <label>Name</label>
            <span class="icon input-icon">
              <i class="far fa-user"></i>
            </span>
            <input type="text" class="input has-icon" v-model="newUser.name" />
          </div>
          <div class="field">
            <label for="name">Organisation</label>
            <span class="icon input-icon">
              <i class="far fa-user"></i>
            </span>
            <div class="select">
              <select v-model="organisationId">
                <option v-for="organisation in organisations" :key="organisation.id" :value="organisation.id">
                  {{ organisation.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="field">
            <label>Service Area</label>
            <span class="icon input-icon">
              <i class="far fa-building"></i>
            </span>
            <input type="text" class="input has-icon" v-model="newUser.service" />
          </div>
          <div class="field">
            <label>Email</label>
            <span class="icon input-icon">
              <i class="far fa-envelope"></i>
            </span>
            <input type="text" class="input has-icon" v-model="newUser.email" />
          </div>
          <div class="field" ref="autocomplete-field">
            <label>Access Level</label>
            <span class="icon input-icon">
              <i class="fas fa-shield-alt"></i>
            </span>
            <div class="select">
              <select v-model="newUser.role">
                <option>admin</option>
                <option>report</option>
                <option>audit</option>
              </select>
            </div>
          </div>
          <div class="field">
            <label> Set two factor authentication</label>
            <input class="input checkbox" type="checkbox" v-model="newUser.twoFactorAuth">
          </div>
        </div>
        <div class="modal-footer">
          <button class="button is-gradient" @click="addNewUser">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SearchUsersMixin from '@/_mixins/search-users.mixin'
import SectionHeader from '@/components/SectionHeader'
import { onMounted } from 'vue'


export default {
  name: 'UserManagement',
  mixins: [SearchUsersMixin],
  components: { SectionHeader },
  data() {
    return {
      newUser: {
        name: null,
        role: null,
        email: null,
        service: null,
        twoFactorAuth: null
      },
      organisationId: null,
      showAddUserModal: false
    }
  },
  computed: {

    ...mapGetters({
      organisations: 'organisation/organisations',
      _users: 'user/users',
    }),
    users() {
      return this._users.map(user => ({
        organisationName: (user.organisation && user.organisation.name) || '',
        ...user
      }))
    }
  },


  methods: {
    ...mapActions({
      createNewUser: 'organisation/createNewUser',
      addUser: 'user/addUser',
      updateTwoFactorAuth: 'user/updateTwoFactorAuthOnUser',
      fetchUsers: 'user/fetchUsers'
    }),
    async addNewUser() {
      let userTrim = {
        name: this.newUser || this.newUser.name ? this.newUser.name.trim() : '',
        service: this.newUser || this.newUser.service ? this.newUser.service.trim() : '',
        email: this.newUser || this.newUser.email ? this.newUser.email.trim() : '',
        role: this.newUser || this.newUser.role ? this.newUser.role.trim() : '',
      }

      const hasFilledAllFields = Object.keys(userTrim)
        .map(key => (key = userTrim[key].length > 0))
        .filter(prop => prop)

      if (hasFilledAllFields.length < 4 || !this.organisationId) {
        return alert('All fields must be filled in')
      }

      try {
        const organisationId = this.organisationId
        const user = { ...userTrim, active: true }
        await this.createNewUser({ user, organisationId })
        this.organisationId = null
        this.newUser = {
          name: null,
          role: null,
          email: null,
          service: null
        }
      } catch (err) {
        alert('Sorry we had a problem saving. Please try again')
      } finally {
        this.showAddUserModal = false
      }
    },
    async sendPasswordReset(user) {
      if (confirm(`Click OK to send a password reset to ${user.name}`)) {
        try {
          await this.ApiService.requestPasswordReset(user.email)
          alert('Email queued for sending. Please ask the customer to check their spam/junk folders if not received within the next five minutes.')
          // this.showEditUserModal = false
        } catch (error) {
          console.log({ error })
          alert('Sorry we had a problem requesting password reset. Please try again')
        }
      }

    },

    async toggleTwoFactorAuth(currentUser) {
      // console.log({currentUser: c})
      const check = confirm(`Are you sure you want to set ${currentUser.name} two factor authentication to ${(!currentUser.twoFactorAut) ? "Deactivate" : "Active"}`)
      if (check) {
        // toggle user two factor 
        currentUser.twoFactorAuth = !currentUser.twoFactorAuth
        this.updateTwoFactorAuth(currentUser)
        const singleUser = this.users.find(user => user.id == currentUser.id)
      }
    }
  },
  async mounted() {
    await this.fetchUsers()
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";
</style>
