<template>
  <DashboardLayout>
    <template v-slot:sidebar>
      <SideBar v-bind:items="sidebarItems" />
    </template>
    <template v-slot:content>
      <router-view />
    </template>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from '@/components/common/DashboardLayout.vue'
import SideBar from '@/components/nav/SideBar.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Dashboard',
  components: { DashboardLayout, SideBar },
  data () {
    return {
      sidebarItems: [
        {
          text: 'Customer Management',
          route: '/super/orgs',
        },
        {
          text: 'User Management',
          route: '/super/users',
        }
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchOrganisations: 'organisation/fetchOrganisations',
      fetchUsers: 'user/fetchUsers',
    })
  },
  async mounted () {
    await this.fetchOrganisations()
    await this.fetchUsers()
  }
}
</script>
