<template>
  <div class="dashboard">
    <div class="banner">
      <p class="banner__text">Invision Annual Review Quality Evaluation Tool</p>
    </div>
    <div class="main">
      <div class="content-container">
        <div class="plan-container">
          <p class="title">Help and Support</p>
          <p class="subtitle">
            If you need help completing the quality assurance process or using /
            accessing the website, please contact your local coordinator
          </p>
          <p class="subtitle">
            If you are a coordinator and need support using the system please
            contact our support desk at
            <a href="mailto:support@invision360.com">support@invision360.com</a>
          </p>
          <div class="faqs">
            <h1>FAQ's</h1>
            <h2>
              1. Is the annual review paperwork available to access (saved or
              stored) on the Invision tool?
            </h2>
            <p>
              No. Annual review paperwork is not saved or stored on the Invision
              tool. The online tool provides a step-by-step guide to audit a
              review, however the Annual Review paperwork is provided to
              auditors through the local authority systems. When you have been
              assigned an Annual Review(s) to be audited, you will receive a
              notification in your email inbox (prompting you to login to
              Invision).
            </p>

            <h2>
              2. I have gone through the auditing process but I can’t submit my
              audit; why?
            </h2>
            <p>
              You will only be able to click 'Submit' when every section has
              been audited (and has a grading against it). Until all sections of
              the Annual Review have been audited and the process is complete,
              the tool will not allow you to submit the audit.
            </p>

            <h2>3. How can I be sure I am auditing the right Annual Review?</h2>
            <p>
              When you have been assigned an AR to be audited, you will receive
              a notification in your email inbox. When you log in to the
              Invision online tool, click on ‘Audit an Annual Review’ on the top
              navigation menu and you will see the Annual Reviews assigned to
              you. Where available, Invision will display the 'C/YP ID' - the ID
              that your organisation has provided. If this hasn't been provided,
              our unique System ID will link to the corresponding Annual Review
              you have been provided access to. When logged onto the tool, you
              will see the C/YP (or System) ID, key stage, setting name and
              primary area of need and they will correspond with the Annual
              Review you have been emailed.
            </p>

            <h2>
              4. I’m a coordinator and I have received an email from an auditor
              stating that they have forgotten their username and /or password.
              How do I resolve this issue?
            </h2>
            <p>
              Access to the platform is granted through an email address and
              password. Coordinators can see all members of the platform on the
              User Management page and search for members by email address or
              name. When clicking on an account from the list, coordinators have
              the option to reset the member’s password directly, or send a
              password reset form to their associated email.
            </p>

            <h2>
              5. I’m the coordinator and I am trying to upload information to
              the platform but they are not uploading.
            </h2>
            <p>
              We have provided an excel template with sample data to upload your
              audits to the platform. Ensure all dates within the provided excel
              document are formatted as such. The tool can read all date
              formats, but the each entry must be recognised by excel as a date
              rather than a string. If one date is entered as text, the tool
              will reject the upload. Extra columns above what has been provided
              in the template will be ignored.
            </p>

            <h2>
              6. I am trying to load the Invision tool, but I only see a white
              screen.
            </h2>
            <p>
              In keeping with best practises, we have disabled access from older
              browsers that don’t have the necessary security controls. The
              platform supports Microsoft Edge v80+, Chrome v79+, Firefox v73+,
              Safari v12+. Support isn’t provided for earlier browsers. If you
              have an earlier version we suggest contacting your IT department
              to request an upgrade. If you have a supported browser and still
              aren’t able to load the platform, please email Invision support at
              support@invision360.com
            </p>

            <h2>
              7. I’m the coordinator and I am trying to upload the excel
              document with all the information but it is not uploading?
            </h2>
            <p>
              Ensure all dates within the excel document are entered in the
              following format: 01/01/2021 (i.e. not 01.01.2021) – excel must
              recognise the numbers as a ‘date’. If one date is entered in the
              wrong format, the excel document will not upload onto the tool.
            </p>

            <h2>
              8. I have received an email to inform me I have an Annual Review
              to audit and when I logon to the Invision tool I can see that the
              audit has been assigned to me; however I have not received the
              actual Annual Review via email from the coordinator (so cannot
              undertake the audit).
            </h2>
            <p>
              If you have an audit assigned with Invision then the coordinator
              has allocated this to you. Contact your coordinator, sharing the
              System ID and/ or C/YP ID that Invision displays and request the
              Annual Review that you have not yet received. The coordinator can
              provide the Annual Review via email to be audited.
            </p>

            <h2>
              9. I am concerned about the quality of (or some components of) the
              Annual Review I have audited and would like to raise this with the
              relevant professional who oversees this Annual Review.
            </h2>
            <p>
              Contact your coordinator directly, indicate you are concerned
              about the plan that you have audited and share the System ID and /
              or C/YP ID. Request to be put in touch directly with the relevant
              SEND Team case officer who oversees the Annual Review in question
              to discuss your queries.
            </p>

            <h2>10. Does Invision allow for the moderation of audits?</h2>
            <p>
              Yes, the same Annual Review can be audited by more than one
              individual, allowing for moderation. Using the C/YP ID, an
              individual with coordinator access rights can view the audits
              completed on the same plan to support a moderation process. For
              example, a LA may decide to identify a ‘set number’ of Annual
              Reviews in each audit cycle to be audited by more than one
              individual for moderation purposes. Searching by C/YP ID will
              bring up all the audits completed on the same plan, providing a
              peer moderation process to be implemented.
            </p>

            <h2>11. Why does the site appear small on my screen?</h2>
            <p>
              In order to fit the amount of information necessary for
              individuals to complete an assessment, we suggest that you use a
              screen resolution that is at least 1200 x 700, or that your
              Windows magnification settings are turned off.
            </p>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <p>
        &copy; {{ year }} Invision Services Limited. Registered in England No.13223149. All
        Rights Reserved.
      </p>
    </footer>
  </div>
</template>

<script>
import SideBar from '@/components/nav/SideBar.vue'
import TopNav from '@/components/nav/TopNav.vue'

export default {
  name: 'Dashboard',
  components: { SideBar, TopNav },
  computed: {
    year () { return new Date().getFullYear() }
  },
  methods: {
    didClickSideBar (event) {
      console.log({ event })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";

.title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.faqs {
  padding: 1rem;
}
.faqs h1 {
  text-decoration: underline;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.faqs h2 {
  font-size: 1.3rem;
  font-weight: 600;
}
.faqs p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: static;
  bottom: 0;
  height: 2rem;
  width: 100vw;
  background: white;
  color: $footer-text;
  p {
    padding-right: $padding1x;
    font-size: $font1x;
  }
}
</style>
