<template>
  <div class="org-manager-container">
    <SectionHeader title="Customer Management and Organisations ">
      <template v-slot:searchbar>
        <div class="search-bar field no-margin">
          <span class="icon input-icon">
            <i class="fas fa-search"></i>
          </span>
          <input type="text" class="input has-icon" placeholder="Find customer" v-model="searchTerm" @input="search" />
        </div>
      </template>
    </SectionHeader>
    <!-- List of Customers -->
    <div class="plan-list">
      <div class="table-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Tier</th>
               <th>License Start Date</th>
              <th>License End Date</th> 
               <th>Contact Name</th>
              <th>Contact Email</th>
              <th>Contact Access</th> 
               <th>Active</th>
              <th>Two Factor Authentication</th> 
            </tr>
          </thead>
          <tbody>
            <tr v-for="org in filteredOrganisations" :key="org.id" @click="editOrganisation(org)">
              <td>{{ org.name ? org.name : ''  }}</td>
              <td>{{ org.license.tier ? org.license.tier : ''   }}</td> 
              <td>{{  org.license.startDate | formatDate }}</td>
              <td>{{ org.license.endDate | formatDate  }}</td> 
              <td>{{ org.users.length > 0 ? org.users[0].name : '' }}</td>
              <td>{{ org.users.length > 0 ? org.users[0].email : '' }}</td>
              <td>{{  org.users.length > 0 ? org.users[0].role : '' }}</td>
              <td>{{ org.license.active  ? org.license.active : ''  }}</td>
              <td>{{ org.twoFactorAuth ? "active" : "deactivate" }}</td> 
            </tr> 
          </tbody>
        </table>
      </div>
      <div class="info-row info-row--flush">
        <button class="button" @click="openAddOrganisationModalOpen">Create new organisation</button>
      </div>
    </div>
    <!-- Create Organisation Modal -->
     <Modal :title="createOrgTitle" v-model="addOrganisationModalOpen" @save="addNewOrganisation">
      <div class="field">
        <label>Name</label>
        <span class="icon input-icon">
          <i class="far fa-user"></i>
        </span>
        <input type="text" class="input has-icon" v-model="newOrganisation.name" />
      </div>
      <div class="field">
        <label for="name">Tier</label>
        <span class="icon input-icon">
          <i class="far fa-building"></i>
        </span>
        <div class="select">
          <select v-model="newOrganisationLicense.tier">
            <option :value="1">Tier 1</option>
            <option :value="2">Tier 2</option>
          </select>
        </div>
      </div>
      <div class="field">
        <label for="name">Licensed Start Date</label>
        <span class="icon input-icon">
          <i class="far fa-calendar-alt"></i>
        </span>
        <input type="date" class="input has-icon" v-model="newOrganisationLicense.startDate" />
      </div>
      <div class="field">
        <label for="name">Licensed End Date</label>
        <span class="icon input-icon">
          <i class="far fa-calendar-alt"></i>
        </span>
        <input type="date" class="input has-icon" v-model="newOrganisationLicense.endDate" />
      </div>
      <div class="field">
        <label>Set Two Factor Authentication </label>
        <input type="checkbox"  class="input checkbox" v-model="newOrganisation.twoFactorAuth">
      </div>
    </Modal>
    <Modal title="Add organisation contacts" v-model="addOrganisationContactsModalOpen"
      @save="addNewOrganisationContacts">
      <div class="contacts-list" v-for="(contact, index) in newOrganisationContacts" :key="index">
        <div class="field">
          <label for="name">Name</label>
          <span class="icon input-icon">
            <i class="far fa-user"></i>
          </span>
          <input type="text" class="input has-icon" v-model="contact.name" @input="isEditing = index" />
        </div>
        <div class="field">
          <label for="name">Email</label>
          <span class="icon input-icon">
            <i class="far fa-envelope"></i>
          </span>
          <input type="text" class="input has-icon" v-model="contact.email" />
        </div>
        <div class="field">
          <label>Service Area</label>
          <span class="icon input-icon">
            <i class="far fa-building"></i>
          </span>
          <input type="text" class="input has-icon" v-model="contact.service" />
        </div>
        <div class="field" ref="autocomplete-field">
          <label for="name">Access Level</label>
          <span class="icon input-icon">
            <i class="fas fa-shield-alt"></i>
          </span>
          <div class="select">
            <select v-model="contact.role">
              <option v-for="role in roles" :key="role" @select="setRole(role, index)">{{ role }}</option>
            </select>
          </div>
          
        </div>
        <div class="field">
            <label>Two Factor Authentication</label>
            <input class="input checkbox" type="checkbox" v-model="contact.twoFactorAuth"/>
          </div>
        <hr v-if="index !== newOrganisationContacts.length - 1">
      </div>
      <div class="field">
        <a @click="newOrganisationContacts.push({})" class="link">
          Add more
        </a>
      </div>
    </Modal>
  </div>
</template>

<script>
import SectionHeader from '@/components/SectionHeader'
import SearchOrganisationsMixin from '@/_mixins/search-organisations.mixin'
import DateFilter from '@/_mixins/date-filter.mixin'
import Modal from '@/components/common/Modal'
import { mapGetters, mapActions } from 'vuex'
import { Role } from '@/_helpers/role'

export default {
  name: 'OrgManagement',
  mixins: [DateFilter, SearchOrganisationsMixin],
  components: { SectionHeader, Modal },
  data() {
    return {
      newOrganisation: {
        name: null,
        twoFactorAuth: false
      },
      newOrganisationLicense: {
        endDate: null,
        startDate: null,
        tier: null
      },
      newOrganisationContacts: [{}],
      addOrganisationModalOpen: false,
      addOrganisationContactsModalOpen: false,
      roles: [...Object.values(Role).filter(role => role !== Role.Super)]
    }
  },
  computed: {
    ...mapGetters({
      organisations: 'organisation/organisations',
    }),
    createOrgTitle() {
      if (this.newOrganisation.id)
        return "Edit " + this.newOrganisation.name
      else
        return "Create new organisation"
    },

  },
  methods: {
    ...mapActions({
      createNewOrganisation: 'organisation/createNewOrganisation',
      updateOrganisation: 'organisation/updateOrganisation'
    }),
    openAddOrganisationModalOpen() {
      this.addOrganisationModalOpen = !this.addOrganisationModalOpen;
    },
    editOrganisation({ license: { startDate, endDate, ...license }, users, ...org }) {
      this.newOrganisation = org
      this.newOrganisationLicense = {
        startDate: startDate.slice(0, 10),
        endDate: endDate.slice(0, 10),
        ...license
      }
      this.addOrganisationModalOpen = true
    },
    addNewOrganisation() {
      console.log("here")
      if (this.newOrganisation.id) {
        // check that two fa has changed
        const currentOrg = this.organisations[this.organisations.findIndex(org => org.id == this.newOrganisation.id)]
        if (currentOrg.twoFactorAuth != this.newOrganisation.twoFactorAuth) {
          const userCheck = confirm(`Are you sure you want to change ${this.newOrganisation.name} two factor authentication setting?`)
          if (!userCheck) return
        }
        this.saveOrganisation()
        return
      }
      const hasOrganisationInfo = !!this.newOrganisation.name
      const hasLicenseInfo = Object.keys(this.newOrganisationLicense)
        .filter(l => l !== null)
        .length >= 3

      if (!hasOrganisationInfo || !hasLicenseInfo) {
        return alert('All fields must be filled in')
      }

      this.addOrganisationModalOpen = false
      this.addOrganisationContactsModalOpen = true
    },
    async saveOrganisation() {
      try {
        await this.updateOrganisation({
          ...this.newOrganisation,
          license: this.newOrganisationLicense
        })
        this.addOrganisationModalOpen = false
        this.newOrganisationContacts = [{}],
          this.newOrganisationLicense = {
            endDate: null,
            startDate: null,
            tier: null
          }

        this.newOrganisation = {
          name: null,
          twoFactorAuth: false
        }
        this.fuse = null;
      } catch (error) {
        console.log(error)
        alert('Sorry we had a problem saving. Please try again')
      }
    },
    async addNewOrganisationContacts() {
      try {
        await this.createNewOrganisation({
          organisation: {
            ...this.newOrganisation,
            license: this.newOrganisationLicense,
            users: this.newOrganisationContacts
          }
        })
        this.addOrganisationContactsModalOpen = false
        this.newOrganisationContacts = [{}],
          this.newOrganisationLicense = {
            endDate: null,
            startDate: null,
            tier: null
          }

        this.newOrganisation = {
          name: null
        }
      } catch (error) {
        alert('Sorry we had a problem saving. Please try again')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
@import "@/assets/styles/components.scss";
@import "@/assets/styles/sections.scss";
@import "@/assets/styles/modal.scss";

hr {
  border: 0;
  border-bottom: 1px dotted $fill-grey;
  margin-bottom: 20px;
  margin-top: 20px;
  width: 100%;
}

a.link {
  position: relative;
  cursor: pointer;

  &:before,
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 0px;
    height: 2px;
    margin: 5px 0 0;
    transition: all 100ms ease-out;
    transition-duration: 0.75s;
    background-color: darken(#ea5393, 5%);
    left: 0;
  }

  &:hover {

    &::before,
    &::after {
      width: 75px;
    }
  }
}
</style>
