<template>
  <DashboardLayout>
    <template v-slot:sidebar>
      <SideBar :items="sidebarItems" />
    </template>
    <template v-slot:topnav>
      <TopNav :role="role" />
    </template>
    <template v-slot:content>
      <FAQs />
    </template>
  </DashboardLayout>
</template>

<script>
import DashboardLayout from '@/components/common/DashboardLayout.vue'
import SideBar from '@/components/nav/SideBar.vue'
import TopNav from '@/components/nav/TopNav.vue'
import FAQs from '@/views/support/Support.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Dashboard',
  components: { DashboardLayout, SideBar, TopNav, FAQs },
  data () {
    return {
      sidebarItems: [
        {
          text: 'Help and Support',
          route: '/support',
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      role: 'user/role'
    })
  },
}
</script>
